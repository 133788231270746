import React from 'react'

interface SelectedSiteContextInterface {
  selectedSiteId: string | undefined
  setSelectedSiteId: (siteId: string) => void
}

const DEFAULT_SITE_CONTEXT = {
  selectedSiteId: undefined,
  setSelectedSiteId: (siteId: string): void => {}
}

const SelectedSiteContext = React.createContext<SelectedSiteContextInterface>(DEFAULT_SITE_CONTEXT)

export function SelectedSiteProvider({ children }): JSX.Element {
  const [selectedSiteId, setSelectedSiteId] = React.useState<string>()
  return (
    <SelectedSiteContext.Provider value={{ selectedSiteId, setSelectedSiteId }}>
      {children}
    </SelectedSiteContext.Provider>
  )
}

export function useSelectedSiteContext() {
  const context = React.useContext(SelectedSiteContext)
  if (!context) {
    throw new Error('useSelectedSiteContext must be used within a SelectedSiteContextProvider')
  }
  return context
}
