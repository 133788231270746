import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { memo } from 'react'

import { RevenueSessionData } from '@contracts/types/GetSessionDataForSiteRequest'

import PortalTexts from '@my-drifter/assets/texts_newportal.json'
import Box from '@my-drifter/components/Box'
import FigmaImage from '@my-drifter/components/FigmaImage'
import FigmaText from '@my-drifter/components/FigmaText'
import FullScreenSpinner from '@my-drifter/components/FullScreenSpinner'
import Layout from '@my-drifter/components/Layout'
import { PortalColors } from '@my-drifter/components/PortalColors'
import { PortalSpacings } from '@my-drifter/components/PortalSpacings'
import RevenueTable from '@my-drifter/components/RevenueTable'
import useRevenueScreenData from '@my-drifter/hooks/useRevenueScreenData'
import Images from '@my-drifter/libs/Images'

import NotAuthorizedScreen from './NotAuthorizedScreen'

function groupAndAggregatePaymentsByMonth(data: RevenueSessionData[]) {
  return data.reduce<
    Record<string, { month: string; date: dayjs.Dayjs; generated: number; paid: number; outstanding: number }>
  >((acc, session) => {
    const sessionDate = dayjs(session.date)
    const date = sessionDate.startOf('month')
    const month = date.format('MMMM YYYY')

    if (!acc[month]) {
      acc[month] = {
        month,
        date,
        generated: 0,
        paid: 0,
        outstanding: 0
      }
    }

    acc[month].generated += session.sumCost
    if (session.paymentStatus === 'paid') {
      acc[month].paid += session.sumCost
    } else if (session.paymentStatus === 'pending') {
      acc[month].outstanding += session.sumCost
    }

    return acc
  }, {})
}

const RevenueScreen = () => {
  const {
    isAllowed,
    isLoadingUser,
    isLoadingData,
    revenueData,
    calendarValue,
    setCalendarValue,
    selectedSiteId,
    setSelectedSiteId
  } = useRevenueScreenData()

  const byMonth = groupAndAggregatePaymentsByMonth(revenueData)

  if (!isAllowed && !isLoadingUser) return <NotAuthorizedScreen />

  return !isLoadingUser ? (
    <Layout selectedSiteId={selectedSiteId} setSelectedSiteId={setSelectedSiteId}>
      <Box gap={PortalSpacings.l} spacing={PortalSpacings.l} fullPadding>
        <TitleBox direction="row" align="center" gap={PortalSpacings.s}>
          <FigmaImage imageKey={Images.paymentsDark} />
          <FigmaText textKey={PortalTexts.revenueReportheaderheadlineMain} />
        </TitleBox>
      </Box>
      <Box fullWidth align="center" backgroundColor="#FFF">
        <RevenueTable data={Object.values(byMonth)} loading={isLoadingData} />
      </Box>
    </Layout>
  ) : (
    <FullScreenSpinner />
  )
}

const TitleBox = styled(Box)`
  color: ${PortalColors.textPrimary};
`

export default memo(RevenueScreen)
