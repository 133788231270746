import { useEffect, useState } from 'react'

import { HikerUserRole } from '@contracts/types/HikerUser'
import { PermitDebtor } from '@contracts/types/PermitDebtor'

import useAppState from './useAppState'
import { useIsDesktop } from './useIsMobile'
import { useSitePermitDebtorPermit } from './useSitePermitDebtorPermit'
import { useSitePermitDebtors } from './useSitePermitDebtors'
import { useSitePermitDebtorsPermits } from './useSitePermitDebtorsPermits'
import { useSelectedSiteContext } from './useSelectedSiteContext'
import { useUser } from './useUser'

const usePermitsScreenData = () => {
  const { state } = useAppState()
  const isDesktop = useIsDesktop()
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)
  const isAllowed = !isLoadingUser && user?.role === HikerUserRole.ADMIN && user?.siteIds && user?.siteIds?.length > 0

  const [isVehicleDrawerOpen, setIsVehicleDrawerOpen] = useState(false)
  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState(false)
  const [isUpdateVehicleDrawerOpen, setIsUpdateVehicleDrawerOpen] = useState(false)
  const [isUpdateUserDrawerOpen, setIsUpdateUserDrawerOpen] = useState(false)
  const [isUpdateStaffMembersDrawerOpen, setIsUpdateStaffMembersDrawerOpen] = useState(false)

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()
  const [selectedSitePermitDebtorId, setSelectedSitePermitDebtorId] = useState<string>()
  const [selectedSitePermitDebtorPermitId, setSelectedSitePermitDebtorPermitId] = useState<string>()

  const { data: sitePermitDebtors } = useSitePermitDebtors(selectedSiteId)
  const { data: sitePermitDebtorsPermits } = useSitePermitDebtorsPermits(selectedSiteId, selectedSitePermitDebtorId)
  const { data: selectedPermit } = useSitePermitDebtorPermit(
    selectedSiteId,
    selectedSitePermitDebtorId,
    selectedSitePermitDebtorPermitId
  )

  const sitePermitDebtorOptions = sitePermitDebtors?.map((permitDebtor: PermitDebtor) => ({
    label: permitDebtor.name,
    value: permitDebtor.id
  }))
  const sitePermitDebtorPermitOptions = sitePermitDebtorsPermits?.map((permit) => ({
    label: permit.name,
    value: permit.id
  }))

  const isAnyDrawerOpen =
    isVehicleDrawerOpen ||
    isUserDrawerOpen ||
    isUpdateVehicleDrawerOpen ||
    isUpdateUserDrawerOpen ||
    isUpdateStaffMembersDrawerOpen

  // When changing the site, reset the selected sitePermitDebtor and sitePermitDebtorPermit.
  useEffect(() => {
    setSelectedSitePermitDebtorId(undefined)
    setSelectedSitePermitDebtorPermitId(undefined)
  }, [selectedSiteId])

  // When changing the sitePermitDebtor, reset the selected sitePermitDebtorPermit.
  useEffect(() => {
    setSelectedSitePermitDebtorPermitId(undefined)
  }, [selectedSitePermitDebtorId])

  useEffect(() => {
    if (user?.siteIds?.length === 1 && user?.id === 57186) {
      setSelectedSiteId('7994')
    } else if (user?.siteIds?.length === 1) {
      setSelectedSiteId(user.siteIds[0])
    }
  }, [isLoadingUser])

  return {
    isAllowed,
    isLoadingUser,
    isDesktop,
    isVehicleDrawerOpen,
    setIsVehicleDrawerOpen,
    isUserDrawerOpen,
    setIsUserDrawerOpen,
    isUpdateVehicleDrawerOpen,
    setIsUpdateVehicleDrawerOpen,
    isUpdateUserDrawerOpen,
    setIsUpdateUserDrawerOpen,
    isUpdateStaffMembersDrawerOpen,
    setIsUpdateStaffMembersDrawerOpen,
    selectedSiteId,
    setSelectedSiteId,
    selectedSitePermitDebtorId,
    setSelectedSitePermitDebtorId,
    selectedSitePermitDebtorPermitId,
    setSelectedSitePermitDebtorPermitId,
    sitePermitDebtors,
    sitePermitDebtorsPermits,
    selectedPermit,
    sitePermitDebtorOptions,
    sitePermitDebtorPermitOptions,
    isAnyDrawerOpen
  }
}

export default usePermitsScreenData
