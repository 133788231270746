import { useEffect, useState } from 'react'

import { RevenueSessionData } from '@contracts/types/GetSessionDataForSiteRequest'
import { HikerUserRole } from '@contracts/types/HikerUser'

import { getRevenueDataForSite } from '@my-drifter/libs/CloudFunctionsApiHandler'

import useAppState from './useAppState'
import { useSelectedSiteContext } from './useSelectedSiteContext'
import { useUser } from './useUser'

const useRevenueScreenData = () => {
  const { state } = useAppState()
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [revenueData, setRevenueData] = useState<RevenueSessionData[]>([])
  const [calendarValue, setCalendarValue] = useState(new Date())
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const isAllowed = !isLoadingUser && user?.role === HikerUserRole.ADMIN && user?.siteIds && user?.siteIds?.length > 0

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true)
      // if (!selectedSiteId || !calendarValue) return setIsLoadingData(false)
      if (!selectedSiteId) {
        return setIsLoadingData(false)
      }
      try {
        const data = await getRevenueDataForSite({
          siteId: selectedSiteId,
          startDate: '2024-01-01', // TODO: dynamic
          endDate: '2024-10-01'
        })
        setRevenueData(data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoadingData(false)
      }
    }

    fetchData()
  }, [selectedSiteId, calendarValue])

  return {
    isAllowed,
    isLoadingUser,
    isLoadingData,
    revenueData,
    calendarValue,
    setCalendarValue,
    selectedSiteId,
    setSelectedSiteId
  }
}

export default useRevenueScreenData
