import { useEffect, useState } from 'react'

import { HikerUserRole } from '@contracts/types/HikerUser'

import { getSessionColumnDataForSite } from '@my-drifter/libs/CloudFunctionsApiHandler'

import useAppState from './useAppState'
import { useIsDesktop } from './useIsMobile'
import { useSelectedSiteContext } from './useSelectedSiteContext'
import { useUser } from './useUser'

const useSessionScreenData = () => {
  const { state } = useAppState()
  const isDesktop = useIsDesktop()
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [sessionsData, setSessionsData] = useState<any[]>([])
  const [calendarValue, setCalendarValue] = useState(new Date())
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const isAllowed = !isLoadingUser && user?.role === HikerUserRole.ADMIN && user?.siteIds && user?.siteIds?.length > 0

  const { selectedSiteId, setSelectedSiteId } = useSelectedSiteContext()

  useEffect(() => {
    if (user?.id === 57186 && user?.siteIds && user.siteIds.length > 0) {
      setSelectedSiteId(user.siteIds[0])
    }
  }, [user])

  const dataTypeOptions = [
    { label: 'Show count', value: 'count' },
    { label: 'Show value', value: 'value' }
  ]
  const [selectedDataType, setSelectedDataType] = useState<'count' | 'value'>('count')

  useEffect(() => {
    setIsLoadingData(true)
    if (!selectedSiteId || !calendarValue) return setIsLoadingData(false)

    getSessionColumnDataForSite({
      siteId: selectedSiteId,
      yearAndMonth: `${calendarValue.getFullYear()}-${calendarValue.getMonth() + 1}`
    })
      .then((data) => {
        setSessionsData(data)
      })
      .finally(() => setIsLoadingData(false))
  }, [selectedSiteId, calendarValue])

  return {
    isAllowed,
    isLoadingUser,
    isDesktop,
    isLoadingData,
    sessionsData,
    calendarValue,
    setCalendarValue,
    selectedSiteId,
    setSelectedSiteId,
    dataTypeOptions,
    selectedDataType,
    setSelectedDataType
  }
}

export default useSessionScreenData
