import styled from '@emotion/styled'
import { memo, ReactNode, useContext } from 'react'

import { Colors } from '@pure/assets/Colors'

import ZIndices from '@web-js/enums/Zindices'

import useAppState from '@my-drifter/hooks/useAppState'
import { useIsDesktop, useIsXLDesktop } from '@my-drifter/hooks/useIsMobile'
import { useUser } from '@my-drifter/hooks/useUser'
import { PermitsContext } from '@my-drifter/store/PermitsProvider'

import Box from './Box'
import DesktopHeader from './DesktopHeader'
import DropdownSelector from './DropdownSelector'
import { LAYOUT_LEFT_PADDING, LAYOUT_LEFT_XL_PADDING, LAYOUT_RIGHT_PADDING } from './HardcodedSizes'
import LeftNavigation from './LeftNavigation'

type Props = {
  children: ReactNode
  siteSVGs?: {
    '7983': string
    '7988': string
    '7990': string
    '7991': string
    '7992': string
    '7995': string
    '7998': string
    '7999': string
    '8000': string
    '8001': string
  }
  isDrawerOpen?: boolean
  selectedSiteId?: string
  isDashboardScreen?: boolean
  isSessionsScreen?: boolean
  setSelectedSiteId?: (value: string) => void
}

const Layout = ({
  children,
  siteSVGs,
  isDrawerOpen,
  selectedSiteId,
  isDashboardScreen,
  isSessionsScreen,
  setSelectedSiteId
}: Props) => {
  const { state } = useAppState()
  const isDesktop = useIsDesktop()
  const isXLDesktop = useIsXLDesktop()
  const { isAnyDrawerOpen } = useContext(PermitsContext)
  const { data: user, isLoading: isLoadingUser } = useUser(localStorage.getItem('userId') || state?.userId)

  const HIKER_ENABLED_SITE_IDS = [
    '7990', // Lidköping real
    '7983', // Importgatan
    '7988', // Ica Maxi Högskolan Halmstad
    '7994', // TestSite
    '7995', // Lidköping fake
    '7998', // Skanor
    '7999', // Råberget Hönekullavägen
    '8000', // Linne
    '8001' // Järngrinden Hönekullavägen
  ] as string[]

  // Temp solution to get site options
  const getSiteOptions = () => {
    if (isSessionsScreen && user?.id === 57186) {
      return [{ label: '7983', value: '7983' }]
    }

    if (isSessionsScreen) {
      return user?.siteIds
        ?.map((siteId) => ({ label: siteId, value: siteId }))
        .filter((site) => HIKER_ENABLED_SITE_IDS.includes(site.value))
    }

    if (isDashboardScreen) {
      return user?.siteIds
        ?.map((siteId) => ({ label: siteId, value: siteId }))
        .filter((site) => Object.keys(siteSVGs ?? []).includes(site.value))
    }

    return user?.siteIds?.map((siteId) => ({ label: siteId, value: siteId })) || []
  }

  return (
    <Container fullWidth fullHeight top={!isDesktop} left={!isDesktop} right={!isDesktop}>
      <LeftNavigation />
      <ChildrenContainer
        fullWidth
        fullHeight
        isDrawerOpen={isAnyDrawerOpen}
        isDesktop={isDesktop}
        isXLDesktop={isXLDesktop}
      >
        <Box fullWidth bottom zIndex={ZIndices.modal}>
          {!isDesktop && !isLoadingUser && (
            <DropdownSelector
              dropdownType="site"
              options={getSiteOptions()}
              selected={selectedSiteId}
              onSelect={(value) => setSelectedSiteId && setSelectedSiteId(value)}
            />
          )}
          {isDesktop && !isLoadingUser && (
            <DesktopHeader
              siteOptions={getSiteOptions()}
              selectedSite={selectedSiteId}
              onSelectSite={(value) => setSelectedSiteId && setSelectedSiteId(value)}
            />
          )}
        </Box>
        {children}
      </ChildrenContainer>
    </Container>
  )
}

const Container = styled(Box)`
  min-height: 100vh;
  background-color: ${Colors.white};
`

const calculateLeftPadding = (isDesktop?: boolean, isXLDesktop?: boolean): string => {
  if (isXLDesktop) {
    return LAYOUT_LEFT_XL_PADDING
  }
  if (isDesktop) {
    return LAYOUT_LEFT_PADDING
  }
  return '0'
}

const ChildrenContainer = styled(Box)<{ isDrawerOpen?: boolean; isDesktop?: boolean; isXLDesktop?: boolean }>`
  padding-left: ${(props) => calculateLeftPadding(props.isDesktop, props.isXLDesktop)};
  padding-right: ${(props) => (props.isDrawerOpen ? LAYOUT_RIGHT_PADDING : 0)};
  transition: padding-right 0.2s ease-in-out;
  background-color: ${Colors.numbersBackground};
`

export default memo(Layout)
