import 'dayjs/locale/sv'

import styled from '@emotion/styled'
import dayjs from 'dayjs'
import React from 'react'
import { Column, usePagination, useSortBy, useTable } from 'react-table'

import { formatText } from '@pure/libs/TextRepositoryHelper'

import PortalTexts from '@my-drifter/assets/texts_newportal.json'
import Images from '@my-drifter/libs/Images'
import { getFigmaText } from '@my-drifter/libs/TextRepository'

import FigmaImage from './FigmaImage'
import FigmaText from './FigmaText'
import { PortalColors } from './PortalColors'
import { PortalSpacings } from './PortalSpacings'

export interface ObjectWithDateProperty {
  date: dayjs.Dayjs
}

interface TableProps<T extends ObjectWithDateProperty> {
  columns: Column<T>[]
  data: T[]
}

function isActiveMonth(date: dayjs.Dayjs): boolean {
  const now = dayjs()
  if (date && date.month() == now.month() && date.year() === now.year()) {
    return true
  }
  return false
}

function DateTable<T extends ObjectWithDateProperty>({ columns, data }: TableProps<T>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    state: { pageIndex, pageSize }
  } = useTable<T>(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 12 } // Set initial page size
    },
    useSortBy, // Enable sorting
    usePagination // Enable pagination
  )

  const totalRows = data.length
  const firstRow = pageIndex * pageSize + 1
  const lastRow = Math.min(firstRow, totalRows)

  return (
    <TableContainer>
      <StyledTable {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key="table-header" id="table-header">
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                  id={column.id}
                  style={tableHeaderStyle}
                >
                  <HeaderCell>
                    <div>{column.render('Header')}</div>
                    <div>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FigmaImage imageKey={Images.sortOrderLowest} />
                        ) : (
                          <FigmaImage imageKey={Images.sortOrderHighest} />
                        )
                      ) : (
                        <FigmaImage imageKey={Images.sortOrderNeutral} />
                      )}
                    </div>
                  </HeaderCell>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr
                {...row.getRowProps()}
                key={row.id}
                id={row.id}
                className={isActiveMonth(row.original.date) ? 'active-row' : ''}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    key={`${cell.row.id} ${cell.column.id}`}
                    id={`${cell.row.id} ${cell.column.id}`}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </StyledTable>
      <PaginationContainer>
        <RowInfo>
          {formatText(
            getFigmaText(PortalTexts.revenueReportrevenueBreakdownpaginationLabelMinimal),
            [`${firstRow} - ${lastRow}`, totalRows],
            {
              splitCharacter: 'xxx'
            }
          )}
        </RowInfo>
        <PaginationButtonContainer>
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            <div className="pagination-button">
              <FigmaImage imageKey={canPreviousPage ? Images.controlBackEnabled : Images.controlBackDisabled} />
            </div>
          </button>
          <>
            {pageOptions.map((_, index) => (
              <button disabled={index === pageIndex} key={index} onClick={() => gotoPage(index)}>
                <div
                  className={
                    index === pageIndex ? 'pagination-button pagination-button--disabled' : 'pagination-button'
                  }
                >
                  {index + 1}
                </div>
              </button>
            ))}
          </>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            <div className="pagination-button">
              <FigmaImage imageKey={canNextPage ? Images.controlNextEnabled : Images.controlNextDisabled} />
            </div>
          </button>
        </PaginationButtonContainer>
        <EntriesPerPageContainer>
          <p>
            <FigmaText textKey={PortalTexts.revenueReportrevenueBreakdownrowEntriesLabel} />
          </p>
          <PageSelect value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
            {[6, 12, 24].map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </PageSelect>
        </EntriesPerPageContainer>
      </PaginationContainer>
    </TableContainer>
  )
}

const TableContainer = styled.div`
  align-self: stretch;
  padding: ${PortalSpacings.l};
`

const tableHeaderStyle = {
  padding: `${PortalSpacings.s} ${PortalSpacings.xs}`,
  background: PortalColors.textWhite,
  fontFamily: 'Poppins',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
  color: PortalColors.navigationHovered
}

const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  flex-grow: 0;
  justify-content: flex-end;
`

const StyledTable = styled.table`
  width: 100%;
  border-radius: ${PortalSpacings.xs};
  border-spacing: 0;
  border-collapse: separate;
  border: 1px solid #d3deed;
  overflow: hidden;

  thead > tr > th:first-of-type > div {
    justify-content: flex-start;
  }

  tbody > tr {
    background: ${PortalColors.textSelectedWhite};
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${PortalColors.navigationHovered};
  }

  td {
    text-align: right;
    padding: ${PortalSpacings.s};
  }

  tbody > tr > td:first-of-type {
    text-align: left;
  }

  tbody > tr > td:first-of-type,
  tbody > tr > td:nth-of-type(2) {
    font-weight: 700;
  }

  tr:not > td(:td-first-of-type) {
    border-right: 1px solid #d3deed;
  }

  thead > tr:not(:first-of-type) > th,
  thead > tr:not(:first-of-type) > td,
  tbody > tr:not(:first-of-type) > th,
  tbody > tr:not(:first-of-type) > td,
  tfoot > tr:not(:first-of-type) > th,
  tfoot > tr:not(:first-of-type) > td,
  tr:not(:first-of-type) > td,
  tr:not(:first-of-type) > th,
  thead:not(:first-of-type),
  tbody:not(:first-of-type),
  tfoot:not(:first-of-type) {
    border-bottom: 1px solid #d3deed;
  }

  .active-row {
    background: ${PortalColors.topBorderBackground};
  }

  .active-row > td {
    border-top: 2px solid ${PortalColors.navigationHovered};
    border-bottom: 2px solid ${PortalColors.navigationHovered};
  }

  .active-row td:first-of-type {
    border-top-left-radius: ${PortalSpacings.xs};
    border-bottom-left-radius: ${PortalSpacings.xs};
    border-left: 2px solid ${PortalColors.navigationHovered};
  }

  .active-row td:last-of-type {
    border-top-right-radius: ${PortalSpacings.xs};
    border-bottom-right-radius: ${PortalSpacings.xs};
    border-right: 2px solid ${PortalColors.navigationHovered};
  }

  .active-row td:not(:last-of-type),
  .active-row td:not(:first-of-type) {
    border-top: 2px solid ${PortalColors.navigationHovered};
    border-bottom: 2px solid ${PortalColors.navigationHovered};
  }
`
const RowInfo = styled.div`
  align-self: center;
  color: ${PortalColors.textSecondary};
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
`

const PaginationButtonContainer = styled.div`
  display: flex;
  padding: ${PortalSpacings.xxs} 0px;
  align-items: center;

  button {
    background: ${PortalColors.textSelectedWhite};
    border: none;
    display: flex;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: ${PortalSpacings.xs};
  }

  .pagination-button {
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    border-radius: ${PortalSpacings.xs};
  }

  button:disabled {
    cursor: default;
  }

  .pagination-button:hover {
    background: ${PortalColors.surfaceActionTertiary};
  }

  .pagination-button--disabled {
    font-weight: 700;
    background: ${PortalColors.infographicsBrand};
    color: ${PortalColors.textWhite};
  }
`

const EntriesPerPageContainer = styled(RowInfo)`
  display: flex;
  gap: ${PortalSpacings.xs};
  margin-left: auto;
`

const PageSelect = styled.select`
  color: ${PortalColors.textPrimary};
  background: ${PortalColors.textSelectedWhite};
  display: flex;
  border-radius: ${PortalSpacings.xxs};
  border: 1px solid #64748b;
  font-family: Poppins;
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  text-align: center;
  align-self: stretch;

  // Workaround to reduce visible vs clickable size
  cursor: pointer;
  padding: ${PortalSpacings.xs} ${PortalSpacings.xs} ${PortalSpacings.xs} ${PortalSpacings.s};
  width: 36px;
  height: 20px;
  box-sizing: content-box;
`

const PaginationContainer = styled.div`
  padding-top: ${PortalSpacings.l};
  display: flex;
  gap: ${PortalSpacings.xs};
`

export default DateTable
