/// <reference types="vite-plugin-svgr/client" />
import controlBackDisabledImage from '@my-drifter/assets/images/control-back-disabled.svg?react'
export const controlBackDisabled = controlBackDisabledImage
import controlBackEnabledImage from '@my-drifter/assets/images/control-back-enabled.svg?react'
export const controlBackEnabled = controlBackEnabledImage
import controlNextDisabledImage from '@my-drifter/assets/images/control-next-disabled.svg?react'
export const controlNextDisabled = controlNextDisabledImage
import controlNextEnabledImage from '@my-drifter/assets/images/control-next-enabled.svg?react'
export const controlNextEnabled = controlNextEnabledImage
import carImg from '@my-drifter/assets/images/car.png'
export const car = carImg
import carIconImg from '@my-drifter/assets/images/car_icon.svg?react'
export const carIcon = carIconImg
import carIconFilledImg from '@my-drifter/assets/images/car_icon_filled.svg?react'
export const carIconFilled = carIconFilledImg
import dividerImg from '@my-drifter/assets/images/divider.svg?react'
export const divider = dividerImg
import logoutImg from '@my-drifter/assets/images/logout.svg?react'
export const logout = logoutImg
import siteImg from '@my-drifter/assets/images/site-regular.svg?react'
export const site = siteImg
import siteFilledImg from '@my-drifter/assets/images/site-filled.svg?react'
export const siteFilled = siteFilledImg
import paymentsImg from '@my-drifter/assets/images/payments.svg?react'
export const payments = paymentsImg
import paymentsFilledImg from '@my-drifter/assets/images/payments-filled.svg?react'
export const paymentsFilled = paymentsFilledImg
import paymentsDarkImg from '@my-drifter/assets/images/payments-dark.svg?react'
export const paymentsDark = paymentsDarkImg
import permitImg from '@my-drifter/assets/images/permits-regular.svg?react'
export const permit = permitImg
import permitFilledImg from '@my-drifter/assets/images/permits-filled.svg?react'
export const permitFilled = permitFilledImg
import dashboardImg from '@my-drifter/assets/images/dashboard.svg?react'
export const dashboard = dashboardImg
import dashboardFilledImg from '@my-drifter/assets/images/dashboard-filled.svg?react'
export const dashboardFilled = dashboardFilledImg
import carOffenseImg from '@my-drifter/assets/images/carOffense.png'
export const carOffense = carOffenseImg
import homeLandingAppStoreImg from '@my-drifter/assets/images/homeLandingAppStore.svg?react'
export const homeLandingAppStore = homeLandingAppStoreImg
import pngGooglePlayImg from '@my-drifter/assets/images/pngGooglePlay.png'
export const pngGooglePlay = pngGooglePlayImg
import successIconImg from '@my-drifter/assets/images/successIcon.svg?react'
export const successIcon = successIconImg
import drifterLogoDarkImg from '@my-drifter/assets/images/drifterLogoDark.svg?react'
export const drifterLogoDark = drifterLogoDarkImg
import drifterLogoLightImg from '@my-drifter/assets/images/drifterLogoLight.svg?react'
export const drifterLogoLight = drifterLogoLightImg
import parkingImg from '@my-drifter/assets/images/parking.svg?react'
export const parking = parkingImg
import sortOrderHighestImg from '@my-drifter/assets/images/sort_order-highest.svg?react'
export const sortOrderHighest = sortOrderHighestImg
import sortOrderLowestImg from '@my-drifter/assets/images/sort_order-lowest.svg?react'
export const sortOrderLowest = sortOrderLowestImg
import sortOrderNeutralImg from '@my-drifter/assets/images/sort_order-neutral.svg?react'
export const sortOrderNeutral = sortOrderNeutralImg
import statMinus1Img from '@my-drifter/assets/images/stat_minus_1.svg?react'
export const statMinus = statMinus1Img
import checkIconOutlinedImg from '@my-drifter/assets/images/checkIconOutlined.svg?react'
export const checkIconOutlined = checkIconOutlinedImg
