import {
  CloudFunctions,
  GetOrCreateUserSecureRequest,
  GetSessionColumnDataForSiteRequest
} from '@contracts/types/CloudFunctionTypes'
import {
  GetSessionDataForSiteRequest,
  GetSessionDataForSiteResponse
} from '@contracts/types/GetSessionDataForSiteRequest'
import { HikerUser } from '@contracts/types/HikerUser'

import { GetSessionDataForSiteForSelectedMonthResponse } from '@functions-my-drifter/types/GetSessionDataForSite'

import { callFunction } from '@web-js/libs/CloudFunctionsApiHandlerHelper'

import { functions } from './FirebaseOptions'

export const getOrCreateUserSecure = (req: GetOrCreateUserSecureRequest): Promise<HikerUser> =>
  callFunction({ functions, cloudfunction: CloudFunctions.getOrCreateUserSecure, data: req }) as Promise<HikerUser>

export const getSessionColumnDataForSite = (
  req: GetSessionColumnDataForSiteRequest
): Promise<GetSessionDataForSiteForSelectedMonthResponse> =>
  callFunction({
    functions,
    cloudfunction: CloudFunctions.getSessionColumnDataForSite,
    data: req
  }) as Promise<GetSessionDataForSiteForSelectedMonthResponse>

export function getRevenueDataForSite(req: GetSessionDataForSiteRequest): Promise<GetSessionDataForSiteResponse> {
  return callFunction({
    functions,
    cloudfunction: CloudFunctions.getFinancialDataForSite,
    data: req
  }) as Promise<GetSessionDataForSiteResponse>
}
